<script>
import { getAllPostByUser } from "@/services/api/posts";
import { userMethods } from "@/state/helpers";

/**
 * Advanced table component
 */
export default {
  data() {
    return {
      tableData: [],
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      currentPage: 1,
      currentUser: null,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],

      fields: [
        { key: "media", sortable: false },
        { key: "details", sortable: false },
      ],
      dataLoading: false,
      playerOptions: {
        height: "280px",
        width: "100%",
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getAllPosts();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...userMethods,
    getAllPosts() {
      this.dataLoading = true;
      this.tableData = [];
      getAllPostByUser({
        userId: this.$store.state.users.seleted._id,
        page: this.currentPage,
        perPage: this.perPage,
        role: "Normal",
      })
        .then((res) => {
          console.log(res.data);
          this.tableData = res.data.response;
          this.rows = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },
    pageChange(val) {
      this.currentPage = val;
      this.getFollowingUsers();
    },
    perPageChange() {
      this.getFollowingUsers();
    },
    videoSelected(location) {
      this.playerOptions.sources.src = "";
      this.playerOptions.sources[0].src = location;
      this.$refs["post-video-modal"].show();
    },
  },
};
</script>

<template>
  <div class="user-post">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="no-data" v-if="!dataLoading && !rows">
              <!-- This is user doesn't have posts! -->
            </div>
            <div class="table-responsive mb-0" style="min-height: 70vh">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :busy="dataLoading"
                :hover="true"
                thead-class="d-none"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="pl-4">Loading...</strong>
                  </div>
                </template>

                <template #cell(media)="data">
                  <carousel :perPage="1" style="width: 120px; height: 180px">
                    <slide
                      v-for="src in data.item.media_files"
                      :key="src.location"
                    >
                      <div
                        v-if="
                          src.location.match(/^http.*\.(jpeg|jpg|gif|png)$/) ==
                          null
                        "
                      >
                        <img
                          style="
                            width: 120px;
                            display: block;
                            margin: auto;
                            z-index: 10;
                          "
                          @click="videoSelected(src.location)"
                          :src="require('@/assets/images/video.png')"
                          alt="Video"
                        />
                      </div>
                      <div v-viewer v-else>
                        <img
                          style="
                            width: 120px;
                            display: block;
                            margin: auto;
                            z-index: 10;
                          "
                          :src="src.location"
                        />
                      </div>
                    </slide>
                  </carousel>
                </template>
                <template #cell(details)="data">
                  <div class="post-details-desc">
                    <div>
                      <span>Caption</span> <span>{{ data.item.caption }}</span>
                    </div>

                    <div>
                      <span>Likes</span>
                      <span>{{ data.item.likes.length }}</span>
                    </div>
                    <div>
                      <span>Comments</span>
                      <span>{{ data.item.comments.length }}</span>
                    </div>
                    <div>
                      <span>Views</span>
                      <span>{{ data.item.views.length }}</span>
                    </div>
                    <div>
                      <span>Location</span>
                      <span>{{ data.item.location_string }}</span>
                    </div>
                    <div>
                      <span>Created</span>
                      <span>
                        {{ moment(data.item.createdAt).format("LLL") }}</span
                      >
                    </div>
                    <div>
                      <span>Modified</span>
                      <span>{{
                        moment(data.item.updatedAt).format("LLL")
                      }}</span>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="rows">
      <div class="col-sm-5">
        <div class="float-left">
          <label
            class="d-inline-flex align-items-center"
            style="min-width: 45px"
          >
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChange"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!--  Modal Start here -->

    <b-modal ref="post-video-modal" size="xl" :hide-footer="true">
      <div style="position: relative">
        <video-player
          class="video-player-box"
          :options="playerOptions"
          :playsinline="true"
        >
        </video-player>
      </div>
    </b-modal>

    <!-- Modal end here -->
  </div>
</template>
<style>
.user-post .row:hover {
  cursor: pointer;
}
.user-post .no-data {
  text-align: center;
  padding-top: 14px;
  font-size: 16px;
  font-weight: 600;
}
.user-post .VueCarousel-slide {
  border-radius: 12px;
  overflow: hidden;
}
.user-post .VueCarousel-dot-container,
.user-post .VueCarousel-dot-container button {
  margin-top: 0px !important;
}
.video-js {
  width: 100%;
}
.video-js .vjs-big-play-button {
  top: calc(50% - 1em);
  left: calc(50% - 1em);
}
.post-details-desc div span:first-child {
  font-weight: 900;
}
.post-details-desc div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  justify-content: space-between;
}
</style>